@import '../../../node_modules/swiper/swiper-bundle.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply font-MMC font-normal text-base text-black bg-white scroll-smooth;
    }

    *:focus {
        outline: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        @apply text-inherit m-0;
    }

    h1 {
        @apply text-[22px] lg:text-[32px] leading-tight font-bold;
    }

    h2 {
        @apply text-2xl	font-bold;
    }

    p {
        @apply text-base font-normal leading-6 ;
    }

    .description {
        ul {
           @apply list-disc pl-4 mb-4;
        }
    }
}

@layer components {
    .btn {
        transition: color .4s ease-out, background-position .4s ease-out !important;
        background-position: 0 50%;
        background-size: 400% 400%;
        background-image: linear-gradient(-60deg,#000 50%,transparent 50%);
    }

    .btn:hover {
        background-position: 99% 50%;
    }

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
        @apply text-brand-grey400;
    }
}

